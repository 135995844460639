import React, { Component } from "react";
import "./Optin.css";
import { SocialIcon } from 'react-social-icons'

class Optin extends Component {
  modal() {
    const modal = document.getElementById("modal");
    modal.classList.toggle("is_open");
  }

  render() {
    return (
      <div className="optin">
        <p>Kwow us more</p>
        <SocialIcon url="https://www.instagram.com/alpine.pickles?igsh=bG5jZG95ZWt5M2Iy&utm_source=qr" />
        <div id="modal">
          <div className="wrapper">
            <h3>Enter Your Email</h3>
            <div className="clearfix">
              <div className="col-8" />
              <div className="col-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Optin;
